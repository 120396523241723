<template>
  <div>
    <v-row>
      <v-col md="12" sm="12" lg="12" class="pa-10 pb-0">
        <v-card elevation="0" class="containerbox" height="80%">
          <breadcrumbComp mainPage="Home" :refresh="refresh" :backSlash="backSlash" :resetFunction="resetFunction" :hideFav="hideFav"/>
        </v-card>
      </v-col>
    </v-row>

  </div>
</template>
<script>
//import EncryptUtility from '../utility/js/encrypt-utility';
import breadcrumbComp from '../common/breadcrumb-comp'
export default {
  data() {
    return {
      hideFav:true,
      refresh: true,
      backSlash: false,

    };
  },
  methods: {

  },
  mounted(){
    //Patch added to fix the menu loading issue
    // let pReload = EncryptUtility.localStorageDecrypt('reload');
    // let isReload = (typeof pReload === 'undefined') ? true : false;    
    // if (isReload) {
    //   EncryptUtility.localStorageEncrypt(
    //             'reload',
    //             JSON.stringify(false),
    //           )
    //   location.reload();
    // }    
  },
  components: {
    breadcrumbComp,
  },
};
</script>